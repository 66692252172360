footer.footer {
  background-color: var(--dark-gray);
}

footer.footer div.footer-container {
  flex: 1;
}
footer.footer div.footer-container div.footer-contactinfos {
  align-items: center;
  margin: 30px 0px;
}
footer.footer div.footer-container div.footer-contactinfos div.footer-contactinfo {
  display: flex;
  font-size: 17px;
  font-family: "Montserrat", "sans-serif";
  color: var(--white);
  font-weight: 400;
  align-items: center;
  margin: 5px 0px;
}
footer.footer div.footer-container div.footer-contactinfos div.footer-contactinfo svg {
  margin-right: 20px;
}
footer.footer div.footer-container hr {
  width: 100%;
  border: 1px solid var(--light-blue);
  margin: 0;
  padding: 0;
}
footer.footer div.footer-container div.footer-linkblocs {
  margin: 30px 0px;
}
footer.footer div.footer-container div.footer-linkbloc {
  border-right: 1px solid var(--light-blue);
  padding: 0px 20px;
  flex: 1;
}
footer.footer div.footer-container div.footer-linkbloc.footer-linkblocright {
  border-right: 0px solid var(--light-blue);
}
footer.footer div.footer-container div.footer-linkbloc h4 {
  font-size: 20px;
  font-family: "Montserrat", "sans-serif";
  color: var(--light-blue);
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
footer.footer div.footer-container div.footer-linkbloc a {
  text-decoration: none;
  font-size: 17px;
  font-family: "Montserrat", "sans-serif";
  color: var(--white);
  font-weight: 400;
  margin: 5px 0px;
  padding: 0;
}
footer.footer div.footer-container div.footer-linkbloc a:hover {
  text-decoration: underline;
}

footer.footer div.footer-container div.footer-texts {
  margin: 30px 0px;
}
footer.footer div.footer-container div.footer-texts p {
  font-size: 17px;
  font-family: "Montserrat", "sans-serif";
  color: var(--white);
  font-weight: 300;
  margin: 5px 0px;
}

footer.footer div.footer-container div.footer-networks {
  justify-content: space-evenly;
  margin: 30px 0px;
}
footer.footer div.footer-container div.footer-copyright p {
  text-align: center;
  margin: 30px 0px;
  font-size: 15px;
  font-family: "Montserrat", "sans-serif";
  color: var(--white);
  font-weight: 400;
}


@media only screen and (max-width: 1000px) {
  footer.footer div.footer-container div.footer-linkbloc {
    border-right: 0px solid var(--light-blue);
  }
  footer.footer div.footer-container div.footer-linkbloc h4 {
    text-align: center;
  }
  footer.footer div.footer-container div.footer-linkbloc a {
    text-align: center;
  }

  footer.footer .footer-linkblocs {
    display: none;
  }
  footer.footer .footer-texts {
    display: none;
  }
}