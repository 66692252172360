.informations .informations-title {
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color: var(--gray);
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  .informations .informations-description {
    text-align: center;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: var(--gray);
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
  .informations .informations-zonepressandco {
    width: 100%;
  }
  .informations .informations-global-container {
    justify-content: space-between;
  }
  .informations .informations-global-container .informations-global-second-container {
    flex: 1;
    padding: 10px 30px;
  }
  
  .informations .badges {
    justify-content: space-evenly;
    padding: 20px 0px;
  }
  .informations .badges .informations-badge {
    width: 200px;
    height: 65px;
  }
  .informations .badges .informations-badge img {
    width: 100%;
    height: 100%;
  }
  
  .informations hr {
    color: var(--light-blue);
    margin: 30px 0px;
  }
  .informations .informations-contactbutton {
    margin: 0px auto;
  }
  