.orderClosed-container {
    padding-top: 100px !important;
}

.orderClosed-center {
    align-items: center;
}

.orderClosed-center h2 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}


.orderClosed-center p {
    font-family: Montserrat, sans-serif;
    font-size: 19px;
    color: var(--gray);
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}