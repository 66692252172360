
div.iconlinetext-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
div.iconlinetext-container div.containerTop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: var(--light-blue);
}
div.iconlinetext-container div.containerTop svg {
  margin: 0px 30px;
}
div.iconlinetext-container div.containerTop .line {
  flex: 1;
  size: 3px;
  color: var(--light-blue);
}

div.iconlinetext-container div.containerBottom {
  padding: 0px 30px;
  text-align: center;
}
div.iconlinetext-container div.containerBottom .title {
  font-size: 25px;
  color: var(--gray);
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  margin: 0; 
  padding: 0;
  margin-bottom: 15px;
}
div.iconlinetext-container div.containerBottom .description {
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  color: var(--light-blue);
  font-weight: 300;
  margin: 0; 
  padding: 0;
}


@media only screen and (max-width: 1000px) {
  div.iconlinetext-container div.containerTop .line {
    display: none;
  }
}