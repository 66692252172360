.notFound-container {
    padding-top: 100px !important;
}

.notFound-center {
    align-items: center;
}

.notFound-center h2 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}
.dynamicPage-container-flex aside p {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: var(--gray);
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
}