.template-content {
  min-height: 200px;
}

.loading-container {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0,0,0,0.3);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loading-container.loading-container-active {
  display: flex;
}
.loading-container img {
  width: 85px;
  margin-bottom: 20px;
}
.loading-container span {
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  color: var(--light-gray);
  font-weight: 500;
}