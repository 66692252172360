.ReactModalPortal h3 {
    font-family: "Montserrat", "sans-serif";
    font-size: 25px;
    color: var(--black);
    font-weight: 500;
    text-align: center;
    margin: 10px 0px;
}

.ReactModalPortal hr {
    width: 100%;
    border: 1px solid var(--light-gray);
    margin: 5px 0px;
}

.ReactModalPortal .modalContent {
    max-height: 500px;
    overflow: auto;
    padding: 0;
    margin: 0;
    width: 100%;
}

.ReactModalPortal .modalContent .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.ReactModalPortal .modalContent .center .horizontal-spaced {
    justify-content: space-evenly;
    width: 100%;
}

.ReactModalPortal .modalContent p.content {
    font-family: "Montserrat", "sans-serif";
    font-size: 15px;
    color: var(--black);
    font-weight: 400;
    text-align: center;
}

.ReactModalPortal .modalContent p.subtitle {
    font-family: "Montserrat", "sans-serif";
    font-size: 20px;
    color: var(--black);
    font-weight: 600;
    text-align: center;
    margin: 10px 0px;
}

.ReactModalPortal .modalContent .flex1 {
    flex: 1;
    align-items: center;
}

.modalSlots .simpletextbutton-container {
    margin-top: 10px
}