.orderproduct-container {
    width: 450px;
    background-color: var(--white);
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    border: 1px solid var(--gray);
    margin: 25px;
    position: relative;
}
.orderproduct-container p {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", "sans-serif";
    font-size: 20px;
    color: var(--dark-gray);
    font-weight: 400;
}
.orderproduct-container .orderproduct-title-container {
    display: flex;
    flex: 6;
}
.orderproduct-container .orderproduct-title {
    display: flex;
    flex: 1;
    justify-content: center;
    overflow-wrap: anywhere;
    text-align: center;
}
.orderproduct-container .orderproduct-subtitle {
    display: flex;
    flex: 1;
    justify-content: center;
    overflow-wrap: anywhere;
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", "sans-serif";
    font-size: 14px;
    color: var(--dark-gray);
    font-weight: 400;
}
.orderproduct-container .orderproduct-subtitle2 {
    display: flex;
    flex: 1;
    justify-content: center;
    overflow-wrap: anywhere;
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", "sans-serif";
    font-size: 13px;
    color: var(--dark-gray);
    font-weight: 600;
    padding-top: 10px;
}
.orderproduct-container .orderproduct-price-container {
    display: flex;
    flex: 1;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid var(--light-blue);
}
.orderproduct-container .orderproduct-price-container p.orderproduct-price-container-number {
    font-weight: 500;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
}
.orderproduct-container .orderproduct-quantity-container {
    display: flex;
    flex: 1;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid var(--light-blue);
}
.orderproduct-container .orderproduct-quantity-container p {
    display: flex;
    flex: 1;
    justify-content: center;
}
.orderproduct-container .orderproduct-quantity-container a {
    color: var(--white);
    border-radius: 30px;
    width: 30px;
    height: 30px;
    background-color: var(--light-blue);
    font-family: "Montserrat", "sans-serif";
    font-size: 25px;
    text-decoration: none;
    font-weight: 400;
    text-align: center;
    position: absolute;
}
.orderproduct-container .orderproduct-quantity-container a:hover {
    font-weight: 500;
    cursor: pointer;
}
.orderproduct-container .orderproduct-quantity-container a.orderproduct-quantity-container-minus {
    right: -15px;
    bottom: -8px;
    line-height: 25px;
}
.orderproduct-container .orderproduct-quantity-container a.orderproduct-quantity-container-plus {
    right: -15px;
    top: -8px;
    line-height: 28px;
}
.orderproduct-container .orderproduct-quantity-container p.orderproduct-quantity-container-number {
    font-weight: 500;
    align-items: center;
}

@media only screen and (max-width: 1000px) {
    .orderproduct-container {
        width: 100%;
    }
}