html, body, #root {
  width: 100%;
  height: 100%;
}

div.waitingpayment-container {
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
div.waitingpayment-container h1 {
  font-size: 35px;
  font-family: 'Montserrat', sans-serif;
  color: var(--dark-blue);
  font-weight: 800;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
div.waitingpayment-container h2 {
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  color: var(--dark-blue);
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 50px;
}