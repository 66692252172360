header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  header.header1 {
    background-image: url("./../../resources/img/header1.jpeg");
  }
  header.header2 {
    background-image: url("./../../resources/img/header2.jpeg");
  }
  header.header3 {
    background-image: url("./../../resources/img/header3.jpeg");
  }

  header div.header-text-container {
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  header div.header-text-container h1 {
    font-family: "Montserrat", "sans-serif";
    color: var(--white);
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  header div.header-text-container p {
    font-family: "Roboto", "sans-serif";
    color: var(--white);
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 60px;
  }

  header div.header-text-container p em {
    color: var(--light-blue);
  }

  header div.header-text-container h2 {
    font-family: "Roboto", "sans-serif";
    color: var(--white);
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  