
.goToTopButton {
  position: fixed;
  right: 32px;
  bottom: 32px;
}
.goToTopButton.visible {
  display: block;
}
.goToTopButton.invisible {
  display: none;
}