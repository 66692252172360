  nav.bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 90px;
    z-index: 100;
    overflow: hidden;
  }
  nav.bar.active {
    background-color: rgba(255, 255, 255, 1);
    height: 70px;
    transition: 0.2s ease all;
    border-bottom: 1px solid var(--light-gray);
  }
  
  nav.bar .center {
    justify-content: space-between;
    height: 100%;
    position: relative;
  }

  nav.bar .bar-menu-button {
    display: none;
  }
  
  nav.bar img.logo {
    height: 90px;
    padding: 10px;
    box-sizing: border-box;
  }
  nav.bar.active img.logo {
    height: 70px;
  }

  nav.bar div.links {
    align-items: center;
    display: flex;
  }

  nav.bar div.links a {
    font-weight: 500;
    text-decoration: none;
    color: var(--light-blue);
    font-size: 20px;
    height: 45px;
    display: flex;
    line-height: 45px;
    align-items: center;
    width: 250px;
    justify-content: center;
  }
  nav.bar div.links a:hover {
    font-weight: 600;
  }
  nav.bar div.links a svg {
    margin-right: 15px;
  }



@media only screen and (max-width: 1000px) {
  nav.bar {
    background-color: rgba(255, 255, 255, 1);
  }
  nav.bar.reduced {
    height: 70px;
  }
  nav.bar.full {
    height: auto!important;
  }
  nav.bar .center {
    align-items: center;
  }
  nav.bar .center .logo {
    height: 70px;
    width: 180px;
  }
  nav.bar .bar-menu-button {
    display: block;
    position: absolute;
    top: 20px;
    right: 0px;
    color: var(--light-blue);
  }
  nav.bar .bar-menu-button:hover {
    cursor: pointer;
  }
  nav.bar.full .bar-menu-button:hover {
    color: var(--gray);
  }
}