.login-container {
    padding-top: 100px !important;
}

.login-center {
    align-items: center;
}

.login-center a {
    text-decoration: none;
    text-align: right;
    margin-bottom: 30px;
    width: 400px;
    color: var(--dark-blue);
}
.login-center a:hover {
    font-weight: 500;
}


@media only screen and (max-width: 1000px) {
    .login-container .labelInputText .inputText, .login-container .labelInputText textarea.inputText {
        width: 100%;
        box-sizing: border-box;
    }
    .login-container .labelInputText {
        width: 100%;
        margin: 20px 0;
    }
    .login-container .form-container {
        width: 80%;
    }

    .login-center a {
        width: 100%;
    }
}