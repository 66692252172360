
div.countertext-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 30px 30px;
  align-items: center;
}

div.countertext-container span {
  font-size: 25px;
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  color: var(--light-blue);
  margin: 0; 
  padding: 0;
  margin-bottom: 15px;
}

div.countertext-container p {
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: var(--gray);
  font-weight: 400;
  margin: 0; 
  padding: 0;
}