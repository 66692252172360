.order-container {
    padding-top: 100px !important;
}

.order-center {
    align-items: center;
}

.order-container main {
    display: flex;
    flex: 2;
    justify-content: center;
}
.order-container aside {
    display: flex;
    flex: 1;
    padding-left: 15px;
    margin-left: 15px;
    border-left: 2px solid var(--light-blue);
    justify-content: center;
}

.order-container .order-subcontainer {
    width: 100%;
}

.order-container h3 {
    font-family: "Montserrat", "sans-serif";
    font-size: 25px;
    color: var(--black);
    font-weight: 500;
    text-align: center;
    margin: 10px 0px;
}

.order-container .simpletextbutton-container {
    margin: 20px auto;
}

p.minTreatment {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: "Montserrat", "sans-serif";
    font-size: 15px;
    color: var(--black);
    font-weight: 300;
}

@media only screen and (max-width: 1000px) {
    .order-container aside {
        border-left: 0px solid var(--light-blue);
        margin: 0;
        padding: 0;
    }
}

.order-container aside .verticalgroup-container {
    width: 100%;
}
.order-container aside .verticalgroup-container article {
    border-top: 1px solid var(--gray);
    border-bottom: 1px solid var(--gray);
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
}
.order-container aside .verticalgroup-container article p {
    margin: 0;
    font-family: "Montserrat", "sans-serif";
    font-size: 15px;
    color: var(--black);
    font-weight: 400;
    text-align: center;
    padding: 10px 10px;
}
.order-container aside .verticalgroup-container article p.order-container-basket-article-name {
    flex: 1;
}
.order-container aside .verticalgroup-container article p.order-container-basket-article-quantity {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.order-container aside .verticalgroup-container p.order-container-basket-price {
    margin: 0;
    font-family: "Montserrat", "sans-serif";
    font-size: 20px;
    color: var(--black);
    font-weight: 400;
    text-align: center;
    padding: 10px 10px;
}
.order-container aside .verticalgroup-container p.order-container-basket-price strong {
    font-weight: 500;
}
.order-container aside .verticalgroup-container .order-container-basket-sticky-container {
    position: sticky;
    top: 90px;
    text-align: center;
}

.order-container main fieldset {
    border: 0px black solid;
    margin-bottom: 10px;
}
