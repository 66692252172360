section.pagesection-container {
  padding: 50px 0px;
}
section.pagesection-container.gray {
  background-color: var(--light-gray);
}

section.pagesection-container .title {
  font-size: 40px;
  text-align: center;
  font-weight: 500;
  color: var(--black);
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

section.pagesection-container .description {
  font-size: 25px;
  text-align: center;
  font-weight: 300;
  color: var(--gray);
  font-family: 'Montserrat', sans-serif;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}