:root {
    --light-blue: #2bb6ec; 
    --white: #ffffff ; 
    --gray: #77797a;
    --light-gray: #e8e8e8;
    --black: #000000;
    --yellow: #f1c40f;
    --dark-gray: #686868;
    --red: #c0392b;
    --green: #27ae60;
    --dark-blue: #2980b9;
}