
button.simpletextbutton-container {
  width: 250px;
  background-color: var(--light-blue);
  border: 0px;
  padding: 10px;
  font-size: 17px;
  font-family: "Montserrat", "sans-serif";
  color: var(--white);
  font-weight: 500;
  border-radius: 6px;
}

button.simpletextbutton-container.simpletextbutton-container-red {
  background-color: var(--red);
}

button.simpletextbutton-container.simpletextbutton-container-dark-gray {
  background-color: var(--dark-gray);
}

button.simpletextbutton-container:hover {
  background-color: var(--dark-blue);
  cursor: pointer;
}

button.simpletextbutton-container.simpletextbutton-container-dark-blue {
  background-color: var(--dark-blue);
}

button.simpletextbutton-container-center {
  margin: auto;
}

button.simpletextbutton-container-small {
  width: 200px;
  font-size: 13px;
}