
div.ratingstarimagetext-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px 30px;
  border: 1px solid var(--light-gray);
  
  margin: 5px;
}

div.ratingstarimagetext-container .personInfos {
  justify-content: space-evenly;
  align-items: center;
}

div.ratingstarimagetext-container .personInfos .personImg {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}

div.ratingstarimagetext-container .personInfos .personName {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  color: var(--gray);
  font-weight: 500;
  margin: 0;
  padding: 0;
}

div.ratingstarimagetext-container .stars {
  margin: 20px 0px;
  justify-content: center;
}
div.ratingstarimagetext-container .stars svg {
  color: var(--yellow);
  margin: 0px 4px;
}

div.ratingstarimagetext-container .text {
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: var(--gray);
  font-weight: 300;
  margin: 0;
  padding: 0;
}


div.ratingstarimagetext-container .vertical-container {
  overflow: auto;
}
div.ratingstarimagetext-container .text-container {
  overflow: auto;
}