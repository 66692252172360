html, body, #root {
  width: 100%;
  height: 100%;
}

div.splashscreen-container {
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}