.contact-container {
    padding-top: 100px !important;
}

.template-content-liteMode .contact-container {
    padding-top: 0px !important;
}

.contact-center {
    align-items: center;
}

.contact-center h2 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.contact-container .sendButton {
    margin: auto;
}


@media only screen and (max-width: 1000px) {
    .contact-container .labelInputText .inputText, .labelInputText textarea.inputText {
        width: 100%;
        box-sizing: border-box;
    }
    .contact-container .labelInputText {
        width: 100%;
    margin: 20px 0;
    }
    .contact-container .form-container {
        width: 80%;
    }
}