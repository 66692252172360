
button.simpleroundiconbutton-container {
  width: 64px;
  background-color: var(--light-blue);
  border: 0px;
  color: var(--white);
  font-weight: 500;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 64px;
}

button.simpleroundiconbutton-container:hover {
  background-color: var(--gray);
  cursor: pointer;
}
