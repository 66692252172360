@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

.center {
    width: 1000px;
    margin: auto;
    display: flex;
}
.center-vertical {
    flex-direction: column;
}

@media only screen and (max-width: 1000px) {
    .center {
        width: 94%;
        margin-left: 3%;
    }
}