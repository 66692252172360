.profile-container {
    padding-top: 100px !important;
}

.profile-center {
    align-items: center;
}

.profile-container .profile-container-vertical {
    width: 100%;
    align-items: center;
}

.profile-container .profile-container-vertical .profile-container-horizontal {
    width: 100%;
    justify-content: space-evenly;
    margin: 30px 0px;
}

.profile-container hr {
    width: 80%;
    margin: auto;
    height: 1px;
    background-color: var(--light-blue);
}
.profile-container hr.profile-hr-margin-bottom {
    margin-bottom: 20px;
}
.profile-container hr.profile-hr-margin-top {
    margin-top: 20px;
}

.profile-container .profile-solde-container {
    align-items: center;
}
.profile-container .profile-solde-container .profile-solde-title {
    font-weight: 300;
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    margin: 0;
    margin-bottom: 20px;
}
.profile-container .profile-solde-container .profile-solde-title strong {
    font-weight: 500;
}

.profile-container .newsletter-container {
    padding-top: 20px;
    padding-bottom: 30px;
}

.profile-container .newsletter-container input {
    margin-right: 10px;
}

.profile-container .newsletter-container label {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    color: var(--black);
    font-weight: 500;
}