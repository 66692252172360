.subscriptions-container {
    padding-top: 100px !important;
}

.subscriptions-center {
    align-items: center;
}

.subscriptions-container .subscriptions-container-horizontal {
    width: 100%;
    justify-content: space-evenly;
    margin: 30px 0px;
}
.subscriptions-container hr {
    width: 80%;
    margin: auto;
    height: 1px;
    background-color: var(--light-blue);
}
.subscriptions-container h4 {
    font-weight: 400;
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    margin: 0;
    margin-top: 15px;
}
.subscriptions-container .subscriptions-container-subtitle {
    font-weight: 300;
    color: var(--black);
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    margin: 0;
    margin-top: 10px;
}