

.ordersection-container {
    margin-top: 30px;
    width: 500px;
}
.ordersection-container .ordersection-container-title {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", "sans-serif";
    font-size: 20px;
    color: var(--dark-gray);
    font-weight: 500;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--gray);
    text-align: center;
    position: relative;
    cursor: pointer;
}

.ordersection-container .ordersection-container-arrow {
    position: absolute;
    top: 0px;
    right: 0px;
}
.ordersection-container .ordersection-container-arrow:hover {
    cursor: pointer;
}
.ordersection-container .ordersection-container-arrow-hidden {
    display: none;
}

.ordersection-container .ordersection-container-arrow-up.ordersection-container-arrow-hidden {
    height: 50px;
}

.ordersection-container .ordersection-container-products-hidden {
    display: none;
}
@media only screen and (max-width: 1000px) {
    .ordersection-container {
        width: 100%;
    }
    .ordersection-container-products {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}