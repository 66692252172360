div.spacer-container {
  width: 100%;
}
div.spacer-container-small {
  height: 20px;
}
div.spacer-container-medium {
  height: 40px;
}
div.spacer-container-large {
  height: 80px;
}