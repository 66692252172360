
.labelInputText {
  display: flex;
  flex-direction: column;
  margin: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  color: var(--black);
  font-weight: 500;
}
.labelInputText .inputText {
  width: 400px;
  height: 35px;
  border: 2px solid var(--light-blue);
  border-radius: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: var(--black);
  padding: 0px 10px;
  font-weight: 400;
  margin-top: 5px;
}
.labelInputText .inputText.inputText-error {
  border: 2px solid var(--red);
}
.labelInputText .inputText.inputText-success {
  border: 2px solid var(--green);
}
.labelInputText .inputText[disabled] {
 background-color: var(--light-gray);
}

.labelInputText textarea.inputText {
  resize: none;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
}