.dynamicPage-container {
    padding-top: 100px !important;
}

.template-content-liteMode .dynamicPage-container {
    padding-top: 0px !important;
}

.dynamicPage-center {
    align-items: center;
}

.dynamicPage-container-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.dynamicPage-container-flex article {
    flex: 4;
    padding-right: 30px;
}
.dynamicPage-container-flex aside {
    margin-left: 10px;
    padding: 10px;
    padding-left: 20px;
    border-left: 2px solid var(--light-blue);
    flex: 1;
}
.dynamicPage-container-flex aside .dynamicPage-contactInfos {
    position: sticky;
    top: 90px;
}
.dynamicPage-container-flex aside h5 {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    color: var(--black);
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    text-align: center;
}
.dynamicPage-container-flex aside a {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: var(--light-blue);
    font-weight: 400;
    text-decoration: none;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    display: block;
}
.dynamicPage-container-flex aside a:hover {
    font-weight: 500;
}
.dynamicPage-container-flex aside p {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    color: var(--gray);
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
}


.dynamicPage-container-flex article h1 {
    font-family: Montserrat, sans-serif;
    font-size: 35px;
    color: var(--black);
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 5px;
    display: block;
}

.dynamicPage-container-flex article h3 {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    color: var(--gray);
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 15px;
    display: block;
}

.dynamicPage-container-flex article h2 {
    font-family: Montserrat, sans-serif;
    font-size: 23px;
    color: var(--black);
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 15px;
    display: block;
}

.dynamicPage-container-flex article p {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: var(--gray);
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 22px;
    min-height: 22px;
}

.dynamicPage-container-flex article strong {
    font-weight: 600;
}

.dynamicPage-container-flex article a {
    color: var(--light-blue);
    text-decoration: none;
}

.dynamicPage-container-flex article img {
    max-width: 50%;
    float: left;
    margin-right: 25px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
    .dynamicPage-container-flex article {
        flex: 4;
        padding-right: 0px;
        margin-bottom: 50px;
    }
  }