.orderConfirmed-container {
    padding-top: 100px !important;
}

.orderConfirmed-center {
    align-items: center;
}

.orderConfirmed-center p.content {
    font-family: "Montserrat", "sans-serif";
    font-size: 20px;
    color: var(--black);
    font-weight: 400;
    text-align: center;
    margin: 15px 0px;
    padding: 0;
}