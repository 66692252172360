
div.horizontalgroup-container {
  display: flex;
  flex-direction: row;
}


@media only screen and (max-width: 1000px) {
  div.horizontalgroup-container {
    flex-direction: column;
  }
  div.horizontalgroup-container.keep-orientation {
    flex-direction: row;
  }
  div.horizontalgroup-containe-mobile-center {
    align-items: center;
  }
}