
div.icontext-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px 30px;
}

div.icontext-container svg {
  margin: 30px auto;
  color: var(--light-blue);
}

div.icontext-container .title {
  font-size: 25px;
  font-family: "Montserrat", "sans-serif";
  font-weight: 500;
  color: var(--gray);
  margin: 0; 
  padding: 0;
  margin-bottom: 15px;
}
div.icontext-container .description p {
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: var(--light-blue);
  font-weight: 300;
  margin: 0; 
  padding: 0;
  margin-bottom: 5px;
}