.passwordLost-container {
    padding-top: 100px !important;
}

.template-content-liteMode .passwordLost-container {
    padding-top: 30px !important;
}

.passwordLost-center {
    align-items: center;
}


@media only screen and (max-width: 1000px) {
    .passwordLost-container .labelInputText .inputText, .passwordLost-container .labelInputText textarea.inputText {
        width: 100%;
        box-sizing: border-box;
    }
    .passwordLost-container .labelInputText {
        width: 100%;
        margin: 20px 0;
    }
    .passwordLost-container .form-container {
        width: 80%;
    }
}