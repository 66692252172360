.timeSlot-container {
  width: 450px;
  background-color: var(--white);
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  border: 1px solid var(--gray);
  margin: 25px;
  position: relative;
}
.timeSlot-container p {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "sans-serif";
  font-size: 20px;
  color: var(--dark-gray);
  font-weight: 400;
}
.timeSlot-container .timeSlot-title {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-wrap: anywhere;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
  color: black;
}
.timeSlot-container .timeSlot-content {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-wrap: anywhere;
  text-align: center;
}
.timeSlot-container .timeSlot-action-container {
  display: flex;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid var(--light-blue);
  justify-content: center;
  align-items: center;
}
.timeSlot-container .timeSlot-action-container svg {
  color: var(--light-blue);
}
.timeSlot-container:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}
.timeSlot-container .timeSlot-infos-container {
  flex: 1;
}
.timeSlot-container .timeSlot-container-icon {
  justify-content: center;
  margin-right: 15px;
}
.timeSlot-container .timeSlot-container-icon svg {
  color: var(--black);
}

@media only screen and (max-width: 1000px) {
  .timeSlot-container {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
  }
}