.signup-container {
    padding-top: 100px !important;
}

.signup-center {
    align-items: center;
}

.signup-container .signup-container-accountTypes {
    justify-content: space-evenly;
    min-width: 80%;
    margin: 15px 0px;
    align-items: center;
}

.signup-container .newsletter-container {
    padding-top: 20px;
    padding-bottom: 30px;
}

.signup-container .newsletter-container input {
    margin-right: 10px;
}

.signup-container .newsletter-container label {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    color: var(--black);
    font-weight: 500;
}

@media only screen and (max-width: 1000px) {
    .signup-container .labelInputText .inputText, .signup-container .labelInputText textarea.inputText {
        width: 100%;
        box-sizing: border-box;
    }
    .signup-container .labelInputText {
        width: 100%;
        margin: 20px 0;
    }
    .signup-container .form-container {
        width: 80%;
    }
}