.radioButton-container {
  width: 450px;
  align-items: center;
  margin: 4px 0px;
}
.radioButton-container:hover {
  cursor: pointer;
}
.radioButton-container p {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "sans-serif";
  font-size: 17px;
  color: var(--dark-gray);
  font-weight: 500;

}
.radioButton-container div.radioButton-container-checkbox {
  width: 20px;
  height: 20px;
  margin: 5px;
  background-color: var(--light-gray);
  border: 1px solid var(--gray);
  border-radius: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioButton-container.radioButton-container-disabled div.radioButton-container-checkbox {
  background-color: var(--gray);
}

.radioButton-container div.radioButton-container-checkbox div {
  display: none;
}
.radioButton-container.radioButton-container-selected div.radioButton-container-checkbox div {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: var(--light-blue);
  border-radius: 12px;
}
